<template>
    <dynamic-link
        class="a-link"
        :to="computedTo"
        :event="event"
        :class="className"
        :title="title"
        native-type="a"
        tabindex="0"
        :has-no-ref="hasNoRef"
        :do-not-change-location="doNotChangeLocation"
        :target="target"
        :rel="rel"
        @click="handleClick"
    >
        <span v-if="tag" class="a-link__tag">{{ tag }}</span>
        <span
            class="a-link__content"
            :class="{ hasGlyph: !!glyph, glyphLeft: glyph && glyphPosition === 'left', glyphRight: glyph && glyphPosition === 'right' }"
        >
            <template v-if="shouldRenderNobr">
                <!-- we need to have the last word attached to the glyph, but &nbsp; only binds to text so we have to have two of them -->
                <!-- eslint-disable-next-line  -->
                <a-dangerous-html v-if="computedText" tag="span" :content="computedText" />&nbsp;<nobr class="nowrap">&nbsp;
                    <a-glyph
                        class="a-link__content__glyph"
                        :name="glyph"
                        :class="{ left: glyphPosition === 'left', right: glyphPosition === 'right' }"
                        :size="glyphSize || undefined"
                    />
                </nobr>
            </template>
            <template v-else>
                <a-dangerous-html v-if="computedText" tag="span" :content="computedText" />
                <a-glyph
                    v-if="glyph"
                    class="a-link__content__glyph"
                    :name="glyph"
                    :class="{ left: glyphPosition === 'left', right: glyphPosition === 'right' }"
                    :size="glyphSize || undefined"
                />
            </template>
        </span>
        <span v-if="desc" class="a-link__desc">{{ desc }}</span>
    </dynamic-link>
</template>

<script>
import { mapState } from 'vuex';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import { SIZES as GLYPH_SIZES } from '@core/components/glyph/constants.js';
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import { TYPES, SIZES, GLYPH_POSITIONS } from './constants.js';

export default {
    name: 'ALink',
    components: {
        AGlyph,
        ADangerousHtml,
        DynamicLink,
    },
    mixins: [breakpoint],
    props: {
        /**
         * Link text
         */
        text: {
            type: String,
            default: undefined,
        },
        /**
         * Link URL
         */
        to: {
            type: String,
            default: undefined,
        },
        /**
         * Link Title
         */
        title: {
            type: String,
            default: undefined,
        },
        /**
         * Link type
         */
        type: {
            type: String,
            default: 'regular',
            validator: (value) => TYPES.includes(value),
        },
        /**
         * Link size
         */
        size: {
            type: String,
            default: 'body',
            validator: (value) => value === undefined || SIZES.includes(value),
        },
        /**
         * Glyph name
         */
        glyph: {
            type: String,
            default: undefined,
        },
        /**
         * Glyph position
         */
        glyphPosition: {
            type: String,
            validator: (value) => value === undefined || GLYPH_POSITIONS.includes(value),
            default: 'right',
        },
        glyphSize: {
            type: String,
            validator: (value) => value === undefined || GLYPH_SIZES.includes(value),
            default: undefined,
        },
        desc: {
            type: String,
            default: undefined,
        },
        tag: {
            type: String,
            default: undefined,
        },
        accent: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hasNoRef: {
            type: Boolean,
            default: false,
        },
        target: {
            type: String,
            default: undefined,
        },
        rel: {
            type: String,
            default: undefined,
        },
        event: {
            type: Object,
            default: undefined,
        },
        modalEvents: {
            type: Object,
            default: undefined,
        },
        doNotChangeLocation: {
            type: Boolean,
            default: false,
        },
        hasNonBreakingGlyph: {
            type: Boolean,
            default: false,
        },
        phoneFromGeo: {
            type: Object,
            default: undefined,
        },
    },
    emits: ['click'],
    data() {
        return {
            updatedText: null,
            updatedTo: null,
        };
    },
    computed: {
        ...mapState({
            location: (state) => state.geolocation?.location || null,
        }),
        country() {
            return this.location?.data?.country?.code;
        },
        shouldRenderNobr() {
            return this.glyph && this.hasNonBreakingGlyph;
        },
        computedTo() {
            return this.updatedTo || this.to;
        },
        computedText() {
            return this.updatedText || this.text;
        },
        className() {
            const { type, size, glyphPosition, accent, disabled, text } = this;
            return {
                [`a-link_type_${type}`]: type || 'regular',
                [`a-link_size_${size}`]: size,
                [`a-link_glyph_${glyphPosition}`]: glyphPosition,
                'a-link_noText': !text,
                'a-link_accent': accent,
                'a-link_disabled': disabled,
            };
        },
    },
    watch: {
        country() {
            if (!this.phoneFromGeo) return;
            this.processGeoPhone();
        },
    },
    methods: {
        handleClick(event) {
            if (this.modalEvents) {
                if (this.modalEvents.forDesktop && !this.isDesktop) return;
                localStorage.setItem('modalEvents', JSON.stringify(this.modalEvents));
            }
            this.$emit('click', event);
        },
        processGeoPhone() {
            const phone = this.phoneFromGeo?.[this.country];
            this.updatedText = phone || this.text;
            this.updatedTo = phone ? `tel:${phone}` : this.to;
        },
    },
};
</script>

<style lang="postcss">
.a-link {
    display: inline-block;
    text-decoration: none;
    background: none;
    padding: 0;
    border: 0;
    outline: 0;
    cursor: pointer;
    color: var(--av-link-primary);
    &:hover {
        color: var(--av-link-primary-hover);
        .a-link__content {
            color: var(--av-link-primary-hover);
            &__glyph {
                fill: var(--av-link-primary-hover);
            }
        }
    }
    &__desc {
        @mixin caption;
        margin-top: 8px;
        display: block;
        color: var(--av-fixed-light);
    }
    &__tag {
        @mixin caption;
        margin-bottom: 8px;
        display: block;
        color: var(--av-fixed-secondary);
    }
    &__content {
        color: var(--av-brand-primary);
        display: inline-block;
        &.hasGlyph {
            position: relative;
        }
        &.glyphLeft {
            padding-inline-start: 24px;
            .a-glyph {
                position: absolute;
            }
            span {
                display: inline-block;
            }
            .nowrap {
                position: absolute;
                top: 0;
                inset-inline-start: 0;
            }
        }
        &.glyphRight {
            .a-glyph {
                margin-bottom: 4px;
            }
            span {
                margin-inline-end: 6px;
            }
        }
        &__glyph {
            fill: var(--av-brand-primary);

            &.left {
                inset-inline-start: 0;
            }
            &.right {
                inset-inline-end: 0;
            }
        }
    }

    /* Different font sizes / Default body */
    &_size {
        &_caption {
            .a-link__content {
                @mixin caption;
                .a-glyph {
                    margin: 0;
                }
            }
        }
        &_body {
            .a-link__content {
                @mixin body;
                .a-glyph {
                    top: 4px;
                }
            }
        }
        &_paragraph {
            .a-link__content {
                @mixin paragraph;
                .a-glyph {
                    top: 4px;
                }
            }
        }
        &_lead {
            .a-link__content {
                @mixin lead;
                .a-glyph {
                    top: 5px;
                }
            }
        }
        &_title {
            .a-link__content {
                @mixin title;
                &.glyphLeft {
                    padding-inline-start: 32px;
                }
                .a-glyph {
                    margin-bottom: 7px;
                    top: 10px;
                }
            }
        }
    }
    &_accent {
        font-weight: 700;
    }
    &_disabled {
        pointer-events: none;
    }

    /* Fix for usages with no text only glyph */
    &_noText {
        .a-link__content {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            .a-glyph {
                position: unset;
            }
            &__glyph {
                position: relative;
            }
        }
    }

    /* Support for download-file and direct links alongside new ones */
    &_type {
        &_direct,
        &_regular {
            .a-link__content {
                color: var(--av-link-primary);
                &__glyph {
                    fill: var(--av-link-primary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-link-primary-hover);
                    &__glyph {
                        fill: var(--av-link-primary-hover);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-link-primary);
                    &__glyph {
                        fill: var(--av-link-primary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-link-primary-active);
                    &__glyph {
                        fill: var(--av-link-primary-active);
                    }
                }
            }
            &.a-link_disabled {
                .a-link__content {
                    color: var(--av-link-primary-light);
                    &__glyph {
                        fill: var(--av-link-primary-light);
                    }
                }
            }
        }
        &_dark {
            .a-link__content {
                color: var(--av-link-secondary);
                &__glyph {
                    fill: var(--av-link-secondary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-link-secondary-hover);
                    &__glyph {
                        fill: var(--av-link-secondary-hover);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-link-secondary);
                    &__glyph {
                        fill: var(--av-link-secondary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-link-secondary-active);
                    &__glyph {
                        fill: var(--av-link-secondary-active);
                    }
                }
            }
            &.a-link_disabled {
                .a-link__content {
                    color: var(--av-link-secondary-light);
                    &__glyph {
                        fill: var(--av-link-secondary-light);
                    }
                    &:hover,
                    &:focus,
                    &:active {
                        color: var(--av-link-secondary-light);
                        &__glyph {
                            fill: var(--av-link-secondary-light);
                        }
                    }
                }
            }
        }
        &_secondary {
            .a-link__content {
                color: var(--av-brand-secondary);
                &__glyph {
                    fill: var(--av-brand-secondary);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-brand-primary);
                    &__glyph {
                        fill: var(--av-brand-primary);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-brand-secondary);
                    &__glyph {
                        fill: var(--av-brand-secondary);
                    }
                }
            }
        }
        &_light {
            .a-link__content {
                color: var(--av-link-inversed);
                &__glyph {
                    fill: var(--av-link-inversed);
                }
            }
            &:hover {
                .a-link__content {
                    color: var(--av-link-inversed-hover);
                    &__glyph {
                        fill: var(--av-link-inversed-hover);
                    }
                }
            }
            &:focus {
                .a-link__content {
                    background: var(--av-brand-secondary-light);
                    color: var(--av-link-inversed);
                    &__glyph {
                        fill: var(--av-link-inversed);
                    }
                }
            }
            &:active,
            &.nuxt-link-active,
            &.nuxt-link-exact-active {
                .a-link__content {
                    background: none;
                    color: var(--av-link-inversed-active);
                    &__glyph {
                        fill: var(--av-link-inversed-active);
                    }
                }
            }
            &.a-link_disabled {
                .a-link__content {
                    color: var(--av-link-inversed-light);
                    &__glyph {
                        fill: var(--av-link-inversed-light);
                    }
                }
            },
            .a-link__tag,
            .a-link__desc {
                color: var(--av-inversed-secondary);
            }
        }
        &_direct {
            .a-link__content {
                @mixin title-accent;
                .a-glyph {
                    margin-bottom: 7px;
                }
            }
            .a-link__content {
                &.glyphLeft {
                    padding-inline-start: 32px;
                }
                .a-glyph {
                    top: 10px;
                }
            }
        }
        &_download-file {
            .a-link__content {
                @mixin lead-accent;
                &__glyph {
                    top: 6px !important;
                    width: 14px;
                    height: 14px;
                    margin-bottom: 4px !important;
                }
            }
            &.a-link_disabled {
                .a-link__content {
                    color: var(--av-link-primary-light);
                    &__glyph {
                        fill: var(--av-link-primary-light);
                    }
                }
            }
        }
    }

    .nowrap {
        white-space: nowrap;
        margin-inline-start: -0.5em;
    }
}
</style>
