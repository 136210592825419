<template>
    <li class="a-accordion-item" :class="{ 'active': isActive }">
        <component :is="titleTag" class="title" @click="onClick">
            <template v-if="title">
                <a-glyph class="icon" name="icon-pointer-right" />
                {{ title }}
            </template>
            <template v-else-if="$slots.title">
                <slot name="title" />
            </template>
        </component>

        <div v-if="$slots.default" ref="content" class="content">
            <div ref="inner" class="content-wrapper">
                <slot />
            </div>
        </div>
    </li>
</template>

<script>
import AGlyph from '@core/components/glyph/glyph.vue';

export default {
    name: 'AAccordionItem',

    components: {
        AGlyph,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        titleTag: {
            type: String,
            default: 'div',
        },

        active: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isActive: false,
    }),

    mounted() {
        this.isActive = this.active;

        this.changeHeightIfActive();

        window.addEventListener('resize', this.changeHeightIfActive);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.changeHeightIfActive);
    },

    methods: {
        changeHeightIfActive() {
            if (this.isActive) this.changeHeight();
        },

        changeHeight() {
            // we use this for transition animation
            const height = this.$refs.content?.scrollHeight;
            this.$refs.content.style.height = height ? `${height}px` : 'auto';
        },

        onClick() {
            const currentState = this.isActive;
            this.$parent.handleAllActives();
            this.isActive = !currentState;
            this.changeHeightIfActive();
            this.$parent.emitActiveChanged(this.isActive);
            this.$parent.emitChangeEvent();
        },

        removeActive() {
            this.isActive = false;
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-accordion-item {
    border-top: var(--av-border);

    &:not(.active) {
        .content {
            height: 0 !important;
        }
    }

    .title {
        cursor: pointer;
        display: flex;
        color: var(--av-nav-primary);
        transition: color ease-out 0.3s;
        padding: 16px 40px 16px 16px;

        &:hover {
            color: var(--av-nav-secondary);

            .icon {
                fill: var(--av-nav-secondary);
            }
        }
    }

    .content {
        overflow: hidden;
        max-height: max-content;
        font-size: 14px;
        line-height: 20px;
        transition: height 0.2s ease-in-out;
    }

    .content-wrapper {
        padding: 8px 40px 16px;
    }

    .icon {
        margin-top: 4px;
        transition: fill ease-out 0.3s, transform 0.25s ease-out;
        flex-shrink: 0;
        fill: var(--av-brand-secondary);
        margin-inline-end: 8px;
    }

    &.active .icon {
        transform: rotate(90deg);
    }
}

[dir='rtl'] {
    .a-accordion-item .icon {
        transform: rotate(180deg);
    }
}
</style>
