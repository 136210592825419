<template>
    <component :is="to ? 'dynamic-link' : 'div'" :to="to ? to : null" :class="['a-small-card', {...className}]">
        <template v-if="image">
            <div v-if="image.startsWith('http')" class="a-picture-image">
                <img
                    alt=""
                    :src="image"
                    class="a-picture__img"
                    loading="lazy"
                />
            </div>
            <template v-else>
                <a-picture :link="image" :alt="imageAlt" class="a-picture-image" />
            </template>
        </template>

        <template v-if="logo">
            <a-picture :link="logo" :alt="logoAlt" class="a-picture-logo" />
        </template>

        <template v-if="icon">
            <a-icon :number="icon" :icon-alt="iconAlt || title" :icon-variant="iconVariant" />
        </template>

        <template v-if="labels?.length">
            <ul class="labels">
                <li v-for="(label, labelIndex) in labels" :key="`label-${labelIndex}`" class="labels-item">
                    <a-label v-bind="label" :size="label.size || 's'">
                        {{ label.text }}
                    </a-label>
                </li>
            </ul>
        </template>

        <h3 v-if="title" class="title">
            {{ title }}
        </h3>

        <a-dangerous-html v-if="text" class="text" :content="text" />

        <div v-if="company" class="company">
            <div class="company-info">
                <span v-if="company.address" class="company-address">
                    {{ company.address }}.
                </span>
                <span v-if="company.phone?.text " class="company-phone">
                    <a-link
                        v-if="company.phone?.to"
                        v-bind="company.phone"
                        :size="company.phone.size || 'body'"
                    />
                    <span v-else class="company-phone-number">{{ company.phone.text }}</span>
                </span>
            </div>
        </div>

        <ul v-if="rate" class="rate-list">
            <li v-for="(item, rateIndex) in rate" :key="`rate-${rateIndex}`" class="rate-item">
                <div class="rate-title">
                    {{ item.title }}
                </div>
                <div class="point-list">
                    <div
                        v-for="(point, pointIndex) in 5"
                        :key="`point-${pointIndex}`"
                        :class="classNameRate(item, pointIndex)"
                    />
                </div>
            </li>
        </ul>

        <div v-if="company?.website?.text" class="company-website">
            <a-link
                :to="companyLink"
                :text="company.website.text"
                :glyph="company.website.glyph || ''"
            />
        </div>

        <ul v-if="buttons" class="buttons">
            <li v-for="(button, buttonIndex) in buttons" :key="`button-${buttonIndex}`">
                <a-button
                    v-bind="button"
                    :size="button.size || 's'"
                    :type="button.type || 'main'"
                />
            </li>
        </ul>
    </component>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AIcon from '@core/components/icon/icon.vue';
import ALabel from '@core/components/label/label.vue';
import ALink from '@core/components/link/link.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'ASmallCard',

    components: {
        AButton,
        ADangerousHtml,
        DynamicLink,
        AIcon,
        ALabel,
        ALink,
        APicture,
    },
    props: {
        /**
         * Title
         */
        title: {
            type: String,
            default: null,
        },

        /**
         * Text
         */
        text: {
            type: String,
            default: null,
        },

        /**
         * Card link
         */
        to: {
            type: String,
            default: null,
        },

        /**
         * Icon String
         */
        icon: {
            type: String,
            default: null,
        },

        /**
         * Icon variant
         */
        iconVariant: {
            type: Boolean,
            default: false,
        },

        /**
         * Icon alt
         */
        iconAlt: {
            type: String,
            default: null,
        },

        /**
         * Logo URL
         */
        logo: {
            type: String,
            default: null,
        },

        /**
         * Logo alt
         */
        logoAlt: {
            type: String,
            default: 'Logo',
        },

        /**
         * Image URL
         */
        image: {
            type: String,
            default: null,
        },

        /**
         * Image alt
         */
        imageAlt: {
            type: String,
            default: 'Acronis',
        },

        /**
         * Rate
         */
        rate: {
            type: Array,
            default: null,
        },

        /**
         * Card Labels
         */
        labels: {
            type: Array,
            default: () => ([]),
        },

        /**
         * Company Card
         */
        company: {
            type: Object,
            default: null,
        },
        buttons: {
            type: Array,
            default: null,
        },

        /**
         * Theme
         */
        theme: {
            type: String,
            validator: (prop) => ['light', 'dark'].includes(prop),
            default: 'light',
        },
    },

    computed: {
        className() {
            return {
                'a-small-card_with-link': this.to,
                'a-small-card_with-rate': this.rate,
                'a-small-card_company': this.company,
                [`${this.theme}-theme`]: true,
            };
        },

        companyLink() {
            return this.company?.website?.to || 'https://www.acronis.com/';
        },
    },

    methods: {
        classNameRate(item, pointIndex) {
            return ['point', { 'point-filled': pointIndex + 1 <= item.points }];
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-small-card {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: var(--av-inversed-primary);
    border: 1px solid var(--av-brand-secondary-light);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(56, 74, 99, 0.1);
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 16px;
    text-decoration: none;
    font-size: 14px;
    transition: box-shadow linear 0.15s;

    .a-icon {
        width: 48px;
        height: 48px;
    }

    .a-picture-logo {
        display: flex;
        height: 64px;
        max-width: 208px;
        align-items: center;
        justify-content: flex-start;

        &:deep(.a-picture__img) {
            width: auto;
            margin: 0;
        }
    }

    .a-picture-image {
        display: flex;
        height: 244px;
        align-items: center;
        justify-content: center;
        margin: -24px -24px 0 -24px;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: relative;
            overflow: hidden;
        }

        &:deep(.a-picture__img) {
            object-fit: cover;
            width: 100%;
            height: 100%;
            margin: 0;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                position: absolute;
                top: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-width: none;
                height: auto;
            }
        }
    }

    .labels {
        display: flex;
        height: 16px;
        gap: 8px;
    }

    .labels-item {
        .a-label {
            vertical-align: top;
        }
    }

    .title {
        @mixin lead-accent;

        color: var(--av-nav-primary);
    }

    .rate-item {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }

    .rate-title {
        @mixin body-accent;
        line-height: 20px;
    }

    .point {
        margin: 0 6px;
        width: 12px;
        height: 12px;
        background-color: var(--av-solid-brand-accent);
        border-radius: 50%;
    }

    .point-list {
        margin: 0 -6px;
        display: flex;
    }

    .point-filled {
        background-color: var(--av-brand-secondary);
    }

    .buttons {
        margin-top: auto;
        padding-top: 40px;

        &:deep(.a-button) {
            width: 100%;
        }
    }

    &.dark-theme {
        background-color: var(--av-nav-primary);

        .title,
        .text,
        .rate-list,
        .company-address {
            color: var(--av-inversed-primary);
        }

        .point {
            background-color: var(--av-inversed-lighter);
        }

        .point-filled {
            background-color: rgba(59, 184, 255, 1);
        }
    }
}

.a-small-card_with-link {
    .title {
        @mixin lead-accent;
        color: var(--av-nav-primary);
        transition: color 0.15s ease-out;
    }

    .text {
        color: var(--av-fixed-secondary);
    }

    &:hover {
        box-shadow: none;
    }
}

.a-small-card_with-rate {
    display: flex;
    flex-direction: column;
}

.a-small-card_company {
    .text {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
    }

    .company {
        margin-top: auto;
    }

    .company-info {
        @mixin descriptor;
        color: var(--av-fixed-secondary);
    }

    .company-phone {
        font-size: 0;

        .a-link {
            display: inline;
        }
    }

    .company-phone-number {
        @mixin descriptor;
    }

    .company-website {
        font-size: 0;

        &:deep(.a-link__content) {
            @mixin caption-accent;
            color: var(--av-brand-primary);

            span {
                margin: 0;
            }
        }

        &:deep(.a-glyph) {
            margin: 0;
        }
    }
}

.company-address {
    color: var(--av-fixed-primary);
}

.rate-list {
    margin-top: 0;
}
</style>
