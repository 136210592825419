/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.a-table__wrapper_type-1 {
  overflow-x: auto;
  max-width: 100%;
}

.a-table__wrapper_type-1 .a-table__scroll-container {
        overflow-x: auto;
        display: block;
        max-width: 100%;
    }

.a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_8-2 .a-table__cell,
            .a-table_layout_8-2 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_8-2 .a-table__cell,
            .a-table_layout_8-2 .a-table__head-cell {

                    flex: 0 0 25%;
  }
                }

@media (min-width: 1024px) {
        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(9% + 9%);
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(9% + (9% * 2));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(9% + (9% * 3));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(9% + (9% * 4));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(9% + (9% * 5));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(9% + (9% * 6));
        }

        .a-table_layout_8-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_8-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(9% + (9% * 7));
        }
                    .a-table_layout_8-2 .a-table__cell,
            .a-table_layout_8-2 .a-table__head-cell {

                    flex: 0 0 9%;
  }
        .a-table_layout_8-2 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_8-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(37% + 9%);
        }

        .a-table_layout_8-2 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_8-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(37% + (9% * 2));
        }

        .a-table_layout_8-2 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_8-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(37% + (9% * 3));
        }

        .a-table_layout_8-2 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_8-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(37% + (9% * 4));
        }

        .a-table_layout_8-2 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_8-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(37% + (9% * 5));
        }

        .a-table_layout_8-2 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_8-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(37% + (9% * 6));
        }

        .a-table_layout_8-2 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_8-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(37% + (9% * 7));
        }
                        .a-table_layout_8-2 .a-table__cell:first-of-type, .a-table_layout_8-2 .a-table__head-cell:first-of-type {

                        flex: 0 0 37%;
  }
                }

.a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_7-1 .a-table__cell,
            .a-table_layout_7-1 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_7-1 .a-table__cell,
            .a-table_layout_7-1 .a-table__head-cell {

                    flex: 0 0 25%;
  }
                }

@media (min-width: 1024px) {
        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(14.286% + 14.286%);
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(14.286% + (14.286% * 2));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(14.286% + (14.286% * 3));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(14.286% + (14.286% * 4));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(14.286% + (14.286% * 5));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(14.286% + (14.286% * 6));
        }

        .a-table_layout_7-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_7-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(14.286% + (14.286% * 7));
        }
                    .a-table_layout_7-1 .a-table__cell,
            .a-table_layout_7-1 .a-table__head-cell {

                    flex: 0 0 14.286%;
  }
                }

.a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_6-1 .a-table__cell,
            .a-table_layout_6-1 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_6-1 .a-table__cell,
            .a-table_layout_6-1 .a-table__head-cell {

                    flex: 0 0 25%;
  }
                }

@media (min-width: 1024px) {
        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(16.66% + 16.66%);
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(16.66% + (16.66% * 2));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(16.66% + (16.66% * 3));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(16.66% + (16.66% * 4));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(16.66% + (16.66% * 5));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(16.66% + (16.66% * 6));
        }

        .a-table_layout_6-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_6-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(16.66% + (16.66% * 7));
        }
                    .a-table_layout_6-1 .a-table__cell,
            .a-table_layout_6-1 .a-table__head-cell {

                    flex: 0 0 16.66%;
  }
                }

.a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_6-2 .a-table__cell,
            .a-table_layout_6-2 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(33.33% + 33.33%);
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(33.33% + (33.33% * 2));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(33.33% + (33.33% * 3));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(33.33% + (33.33% * 4));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(33.33% + (33.33% * 5));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(33.33% + (33.33% * 6));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(33.33% + (33.33% * 7));
        }
                    .a-table_layout_6-2 .a-table__cell,
            .a-table_layout_6-2 .a-table__head-cell {

                    flex: 0 0 33.3%;
  }
                }

@media (min-width: 1024px) {
        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_6-2 .a-table__cell,
            .a-table_layout_6-2 .a-table__head-cell {

                    flex: 0 0 25%;
  }
                }

@media (min-width: 1280px) {
        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(20% + 20%);
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(20% + (20% * 2));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(20% + (20% * 3));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(20% + (20% * 4));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(20% + (20% * 5));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(20% + (20% * 6));
        }

        .a-table_layout_6-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_6-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(20% + (20% * 7));
        }
                    .a-table_layout_6-2 .a-table__cell,
            .a-table_layout_6-2 .a-table__head-cell {

                    flex: 0 0 20%;
  }
                }

.a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_5-1 .a-table__cell,
            .a-table_layout_5-1 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_5-1 .a-table__cell,
            .a-table_layout_5-1 .a-table__head-cell {

                    flex: 0 0 25%;
  }
                }

@media (min-width: 1024px) {
        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(16.66% + 16.66%);
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(16.66% + (16.66% * 2));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(16.66% + (16.66% * 3));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(16.66% + (16.66% * 4));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(16.66% + (16.66% * 5));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(16.66% + (16.66% * 6));
        }

        .a-table_layout_5-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_5-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(16.66% + (16.66% * 7));
        }
                    .a-table_layout_5-1 .a-table__cell,
            .a-table_layout_5-1 .a-table__head-cell {

                    flex: 0 0 16.66%;
  }
        .a-table_layout_5-1 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_5-1 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(33.33% + 16.66%);
        }

        .a-table_layout_5-1 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_5-1 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(33.33% + (16.66% * 2));
        }

        .a-table_layout_5-1 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_5-1 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(33.33% + (16.66% * 3));
        }

        .a-table_layout_5-1 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_5-1 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(33.33% + (16.66% * 4));
        }

        .a-table_layout_5-1 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_5-1 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(33.33% + (16.66% * 5));
        }

        .a-table_layout_5-1 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_5-1 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(33.33% + (16.66% * 6));
        }

        .a-table_layout_5-1 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_5-1 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(33.33% + (16.66% * 7));
        }
                        .a-table_layout_5-1 .a-table__cell:first-of-type, .a-table_layout_5-1 .a-table__head-cell:first-of-type {

                        flex: 0 0 33.33%;
  }
                }

.a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_5-2 .a-table__cell,
            .a-table_layout_5-2 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(16.66% + 16.66%);
        }

        .a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(16.66% + (16.66% * 2));
        }

        .a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(16.66% + (16.66% * 3));
        }

        .a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(16.66% + (16.66% * 4));
        }

        .a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(16.66% + (16.66% * 5));
        }

        .a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(16.66% + (16.66% * 6));
        }

        .a-table_layout_5-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_5-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(16.66% + (16.66% * 7));
        }
                    .a-table_layout_5-2 .a-table__cell,
            .a-table_layout_5-2 .a-table__head-cell {

                    flex: 0 0 16.66%;
  }
        .a-table_layout_5-2 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_5-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(33.33% + 50%);
        }

        .a-table_layout_5-2 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_5-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(33.33% + (50% * 2));
        }

        .a-table_layout_5-2 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_5-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(33.33% + (50% * 3));
        }

        .a-table_layout_5-2 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_5-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(33.33% + (50% * 4));
        }

        .a-table_layout_5-2 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_5-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(33.33% + (50% * 5));
        }

        .a-table_layout_5-2 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_5-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(33.33% + (50% * 6));
        }

        .a-table_layout_5-2 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_5-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(33.33% + (50% * 7));
        }
                        .a-table_layout_5-2 .a-table__cell:first-of-type, .a-table_layout_5-2 .a-table__head-cell:first-of-type {

                        flex: 0 0 33.33%;
  }

                        .a-table_layout_5-2 .a-table__cell:first-of-type > *, .a-table_layout_5-2 .a-table__head-cell:first-of-type > * {
                            padding-inline-end: 27%;
                        }
                }

.a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_4-1 .a-table__cell,
            .a-table_layout_4-1 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_4-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_4-1 .a-table__cell,
            .a-table_layout_4-1 .a-table__head-cell {

                    flex: 0 0 25%;
  }
                }

.a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_4-2 .a-table__cell,
            .a-table_layout_4-2 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_4-2 .a-table__cell,
            .a-table_layout_4-2 .a-table__head-cell {

                    flex: 0 0 25%;
  }
                }

@media (min-width: 1024px) {
        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(16.66% + 16.66%);
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(16.66% + (16.66% * 2));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(16.66% + (16.66% * 3));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(16.66% + (16.66% * 4));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(16.66% + (16.66% * 5));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(16.66% + (16.66% * 6));
        }

        .a-table_layout_4-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(16.66% + (16.66% * 7));
        }
                    .a-table_layout_4-2 .a-table__cell,
            .a-table_layout_4-2 .a-table__head-cell {

                    flex: 0 0 16.66%;
  }
        .a-table_layout_4-2 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_4-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 16.66%);
        }

        .a-table_layout_4-2 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_4-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (16.66% * 2));
        }

        .a-table_layout_4-2 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_4-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (16.66% * 3));
        }

        .a-table_layout_4-2 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_4-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (16.66% * 4));
        }

        .a-table_layout_4-2 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_4-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (16.66% * 5));
        }

        .a-table_layout_4-2 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_4-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (16.66% * 6));
        }

        .a-table_layout_4-2 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_4-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (16.66% * 7));
        }
                        .a-table_layout_4-2 .a-table__cell:first-of-type, .a-table_layout_4-2 .a-table__head-cell:first-of-type {

                        flex: 0 0 50%;
  }

                        .a-table_layout_4-2 .a-table__cell:first-of-type > *, .a-table_layout_4-2 .a-table__head-cell:first-of-type > * {
                            padding-inline-end: 35%;
                        }
                }

.a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_4-3 .a-table__cell,
            .a-table_layout_4-3 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(16.66% + 16.66%);
        }

        .a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(16.66% + (16.66% * 2));
        }

        .a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(16.66% + (16.66% * 3));
        }

        .a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(16.66% + (16.66% * 4));
        }

        .a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(16.66% + (16.66% * 5));
        }

        .a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(16.66% + (16.66% * 6));
        }

        .a-table_layout_4-3 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-3 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(16.66% + (16.66% * 7));
        }
                    .a-table_layout_4-3 .a-table__cell,
            .a-table_layout_4-3 .a-table__head-cell {

                    flex: 0 0 16.66%;
  }
        .a-table_layout_4-3 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_4-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 16.66%);
        }

        .a-table_layout_4-3 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_4-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (16.66% * 2));
        }

        .a-table_layout_4-3 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_4-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (16.66% * 3));
        }

        .a-table_layout_4-3 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_4-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (16.66% * 4));
        }

        .a-table_layout_4-3 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_4-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (16.66% * 5));
        }

        .a-table_layout_4-3 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_4-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (16.66% * 6));
        }

        .a-table_layout_4-3 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_4-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (16.66% * 7));
        }
                        .a-table_layout_4-3 .a-table__cell:first-of-type, .a-table_layout_4-3 .a-table__head-cell:first-of-type {

                        flex: 0 0 50%;
  }

                        .a-table_layout_4-3 .a-table__cell:first-of-type > *, .a-table_layout_4-3 .a-table__head-cell:first-of-type > * {
                            padding-inline-end: 18%;
                        }
                }

@media (min-width: 1024px) {
                        .a-table_layout_4-3 .a-table__cell:first-of-type > *, .a-table_layout_4-3 .a-table__head-cell:first-of-type > * {
                            padding-inline-end: 35%;
                        }
                }

.a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_4-4 .a-table__cell,
            .a-table_layout_4-4 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(16.66% + 16.66%);
        }

        .a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(16.66% + (16.66% * 2));
        }

        .a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(16.66% + (16.66% * 3));
        }

        .a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(16.66% + (16.66% * 4));
        }

        .a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(16.66% + (16.66% * 5));
        }

        .a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(16.66% + (16.66% * 6));
        }

        .a-table_layout_4-4 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_4-4 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(16.66% + (16.66% * 7));
        }
                    .a-table_layout_4-4 .a-table__cell,
            .a-table_layout_4-4 .a-table__head-cell {

                    flex: 0 0 16.66%;
  }
        .a-table_layout_4-4 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_4-4 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 16.66%);
        }

        .a-table_layout_4-4 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_4-4 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (16.66% * 2));
        }

        .a-table_layout_4-4 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_4-4 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (16.66% * 3));
        }

        .a-table_layout_4-4 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_4-4 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (16.66% * 4));
        }

        .a-table_layout_4-4 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_4-4 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (16.66% * 5));
        }

        .a-table_layout_4-4 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_4-4 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (16.66% * 6));
        }

        .a-table_layout_4-4 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_4-4 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (16.66% * 7));
        }
                        .a-table_layout_4-4 .a-table__cell:first-of-type, .a-table_layout_4-4 .a-table__head-cell:first-of-type {

                        flex: 0 0 50%;
  }
                }

.a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_3-1 .a-table__cell,
            .a-table_layout_3-1 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(33.33% + 33.33%);
        }

        .a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(33.33% + (33.33% * 2));
        }

        .a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(33.33% + (33.33% * 3));
        }

        .a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(33.33% + (33.33% * 4));
        }

        .a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(33.33% + (33.33% * 5));
        }

        .a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(33.33% + (33.33% * 6));
        }

        .a-table_layout_3-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_3-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(33.33% + (33.33% * 7));
        }
                    .a-table_layout_3-1 .a-table__cell,
            .a-table_layout_3-1 .a-table__head-cell {

                    flex: 0 0 33.33%;
  }
                }

.a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_3-2 .a-table__cell,
            .a-table_layout_3-2 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_3-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_3-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_3-2 .a-table__cell,
            .a-table_layout_3-2 .a-table__head-cell {

                    flex: 0 0 25%;
  }
        .a-table_layout_3-2 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_3-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 25%);
        }

        .a-table_layout_3-2 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_3-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (25% * 2));
        }

        .a-table_layout_3-2 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_3-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (25% * 3));
        }

        .a-table_layout_3-2 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_3-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (25% * 4));
        }

        .a-table_layout_3-2 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_3-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (25% * 5));
        }

        .a-table_layout_3-2 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_3-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (25% * 6));
        }

        .a-table_layout_3-2 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_3-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (25% * 7));
        }
                        .a-table_layout_3-2 .a-table__cell:first-of-type, .a-table_layout_3-2 .a-table__head-cell:first-of-type {

                        flex: 0 0 50%;
  }

                        .a-table_layout_3-2 .a-table__cell:first-of-type > *, .a-table_layout_3-2 .a-table__head-cell:first-of-type > * {
                            padding-inline-end: 18%;
                        }
                }

@media (min-width: 1024px) {
                        .a-table_layout_3-2 .a-table__cell:first-of-type > *, .a-table_layout_3-2 .a-table__head-cell:first-of-type > * {
                            padding-inline-end: 35%;
                        }
                }

.a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_3-3 .a-table__cell,
            .a-table_layout_3-3 .a-table__head-cell {

                flex: 0 0 50%;
}

@media (min-width: 768px) {
        .a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(25% + 25%);
        }

        .a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(25% + (25% * 2));
        }

        .a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(25% + (25% * 3));
        }

        .a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(25% + (25% * 4));
        }

        .a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(25% + (25% * 5));
        }

        .a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(25% + (25% * 6));
        }

        .a-table_layout_3-3 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_3-3 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(25% + (25% * 7));
        }
                    .a-table_layout_3-3 .a-table__cell,
            .a-table_layout_3-3 .a-table__head-cell {

                    flex: 0 0 25%;
  }
        .a-table_layout_3-3 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_3-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 25%);
        }

        .a-table_layout_3-3 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_3-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (25% * 2));
        }

        .a-table_layout_3-3 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_3-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (25% * 3));
        }

        .a-table_layout_3-3 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_3-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (25% * 4));
        }

        .a-table_layout_3-3 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_3-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (25% * 5));
        }

        .a-table_layout_3-3 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_3-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (25% * 6));
        }

        .a-table_layout_3-3 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_3-3 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (25% * 7));
        }
                        .a-table_layout_3-3 .a-table__cell:first-of-type, .a-table_layout_3-3 .a-table__head-cell:first-of-type {

                        flex: 0 0 50%;
  }
                }

.a-table_layout_2-1 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_2-1 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(50% + 50%);
        }

.a-table_layout_2-1 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_2-1 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(50% + (50% * 2));
        }

.a-table_layout_2-1 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_2-1 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(50% + (50% * 3));
        }

.a-table_layout_2-1 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_2-1 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(50% + (50% * 4));
        }

.a-table_layout_2-1 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_2-1 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(50% + (50% * 5));
        }

.a-table_layout_2-1 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_2-1 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(50% + (50% * 6));
        }

.a-table_layout_2-1 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_2-1 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(50% + (50% * 7));
        }

.a-table_layout_2-1 .a-table__cell,
            .a-table_layout_2-1 .a-table__head-cell {

                flex: 0 0 50%;
}

.a-table_layout_2-2 .a-table__cell.a-table__cell_colspan_2, .a-table_layout_2-2 .a-table__head-cell.a-table__cell_colspan_2 {
            flex: 0 0 calc(33.33% + 33.33%);
        }

.a-table_layout_2-2 .a-table__cell.a-table__cell_colspan_3, .a-table_layout_2-2 .a-table__head-cell.a-table__cell_colspan_3 {
            flex: 0 0 calc(33.33% + (33.33% * 2));
        }

.a-table_layout_2-2 .a-table__cell.a-table__cell_colspan_4, .a-table_layout_2-2 .a-table__head-cell.a-table__cell_colspan_4 {
            flex: 0 0 calc(33.33% + (33.33% * 3));
        }

.a-table_layout_2-2 .a-table__cell.a-table__cell_colspan_5, .a-table_layout_2-2 .a-table__head-cell.a-table__cell_colspan_5 {
            flex: 0 0 calc(33.33% + (33.33% * 4));
        }

.a-table_layout_2-2 .a-table__cell.a-table__cell_colspan_6, .a-table_layout_2-2 .a-table__head-cell.a-table__cell_colspan_6 {
            flex: 0 0 calc(33.33% + (33.33% * 5));
        }

.a-table_layout_2-2 .a-table__cell.a-table__cell_colspan_7, .a-table_layout_2-2 .a-table__head-cell.a-table__cell_colspan_7 {
            flex: 0 0 calc(33.33% + (33.33% * 6));
        }

.a-table_layout_2-2 .a-table__cell.a-table__cell_colspan_8, .a-table_layout_2-2 .a-table__head-cell.a-table__cell_colspan_8 {
            flex: 0 0 calc(33.33% + (33.33% * 7));
        }

.a-table_layout_2-2 .a-table__cell,
            .a-table_layout_2-2 .a-table__head-cell {

                flex: 0 0 33.33%;
}

.a-table_layout_2-2 .a-table__cell:first-of-type.a-table__cell_colspan_2, .a-table_layout_2-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_2 {
            flex: 0 0 calc(66.66% + 33.33%);
        }

.a-table_layout_2-2 .a-table__cell:first-of-type.a-table__cell_colspan_3, .a-table_layout_2-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_3 {
            flex: 0 0 calc(66.66% + (33.33% * 2));
        }

.a-table_layout_2-2 .a-table__cell:first-of-type.a-table__cell_colspan_4, .a-table_layout_2-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_4 {
            flex: 0 0 calc(66.66% + (33.33% * 3));
        }

.a-table_layout_2-2 .a-table__cell:first-of-type.a-table__cell_colspan_5, .a-table_layout_2-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_5 {
            flex: 0 0 calc(66.66% + (33.33% * 4));
        }

.a-table_layout_2-2 .a-table__cell:first-of-type.a-table__cell_colspan_6, .a-table_layout_2-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_6 {
            flex: 0 0 calc(66.66% + (33.33% * 5));
        }

.a-table_layout_2-2 .a-table__cell:first-of-type.a-table__cell_colspan_7, .a-table_layout_2-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_7 {
            flex: 0 0 calc(66.66% + (33.33% * 6));
        }

.a-table_layout_2-2 .a-table__cell:first-of-type.a-table__cell_colspan_8, .a-table_layout_2-2 .a-table__head-cell:first-of-type.a-table__cell_colspan_8 {
            flex: 0 0 calc(66.66% + (33.33% * 7));
        }

.a-table_layout_2-2 .a-table__cell:first-of-type, .a-table_layout_2-2 .a-table__head-cell:first-of-type {

                    flex: 0 0 66.66%;
}

.a-table_height_small .a-table__cell {
                padding-top: 16px;
                padding-bottom: 16px;
            }

.a-table_height_bigger .a-table__cell {
                padding-top: 24px;
                padding-bottom: 24px;
            }

.a-table_sticky .a-table__head {
                position: sticky;
                z-index: 1;
                top: 0;
                margin: 0 -16px;
                padding: 0 16px;
                box-shadow: var(--av-shadow-small);
                clip-path: inset(0 0 -20px 0);
            }

@media (min-width: 576px) {

.a-table_sticky .a-table__head {
                    margin: 0 -32px;
                    padding: 0 32px
            }
                }

@media (min-width: 1280px) {

.a-table_sticky .a-table__head {
                    margin: 0 -64px;
                    padding: 0 64px
            }
                }

.a-table_sticky .a-table__head-cell {
                border-bottom: none;
            }

.a-table_with_submenu.a-table_sticky .a-table__head {
                    top: 58px;
                }

@media (min-width: 576px) {

.a-table_with_submenu.a-table_sticky .a-table__head {
                        top: 65px
                }
                    }

.a-table_without-optional .a-table__row_optional {
            display: none;
        }

.a-table_align_left .a-table__cell:not(:first-of-type), .a-table_align_left .a-table__head-cell:not(:first-of-type) {
                    text-align: start;
                }

.a-table_align_left .a-table__check {
                position: relative;
                height: 100%;
            }

.a-table_align_left .a-table__check-icon {
                    top: 50%;
                    inset-inline-start: 0;
                    transform: translate(0, -50%);
                }

.a-table_align_center .a-table__cell:not(:first-of-type), .a-table_align_center .a-table__head-cell:not(:first-of-type) {
                    text-align: center;
                }

.a-table_align_center:deep(.cta-description) {
                justify-content: center;
            }

.a-table__head-mobile {
        display: none;
    }

@media (max-width: 767px) {
                .a-table_mobile-type-column .a-table__head {
                    display: none;
                }

                .a-table_mobile-type-column .a-table__category {
                    display: flex;
                }

                .a-table_mobile-type-column .a-table__glyph {
                    margin-inline-end: 16px;
                }

                .a-table_mobile-type-column .a-table__row {
                    flex-wrap: wrap;
                }

                .a-table_mobile-type-column .a-table__cell {
                    flex-basis: 100%;
                }

                    .a-table_mobile-type-column .a-table__cell:not(:last-child) {
                        border-bottom: 0;
                    }

                    .a-table_mobile-type-column .a-table__cell:last-child {
                        padding-bottom: 32px;
                        margin-bottom: 24px;
                    }

                .a-table_mobile-type-column .a-table__head-mobile {
                    display: block;
                }

                .a-table_mobile-type-column .a-table__head-mobile-field {
                    margin-bottom: 16px;
                }
        }

.a-table .data-center-figure {
        fill: green;
    }

.a-table__head {
        display: block;
        overflow-x: scroll;
        background-color: var(--av-inversed-primary);
        scrollbar-width: none;
        margin: 0 -16px;
        padding: 0 calc(16px - 8px);
    }

.a-table__head::-webkit-scrollbar {
            height: 0;
            background: rgba(255, 255, 255, 0);
        }

@media (min-width: 576px) {

.a-table__head {
            margin: 0 -32px;
            padding: 0 calc(32px - 8px)
    }
        }

@media (min-width: 1280px) {

.a-table__head {
            margin: 0 -64px;
            padding: 0 calc(64px - 8px)
    }
        }

@media (min-width: 1440px) {

.a-table__head {
            margin: 0 -8px;
            padding: 0
    }
        }

.a-table__head-row {
        display: flex;
    }

@media (max-width: 767px) {
                    .a-table__head-row.a-table__head-row_no-scroll .a-table__head-cell {
                        flex-basis: auto;
                        flex-shrink: 1;
                    }
            }

.a-table__head-cell {
        display: block;
        padding: 32px 8px;
        border-bottom: 1px solid var(--av-brand-secondary-light);
        overflow: hidden;
    }

.a-table__head-cell:first-of-type {
            text-align: start;
        }

.a-table__head-cell_align_left {
                text-align: start !important;
            }

.a-table__head-cell_align_center {
                text-align: center !important;
            }

.a-table__head-cell_align_right {
                text-align: end !important;
            }

.a-table__head-text,
    .a-table__head-mobile-text {
        font-size: 16px;
        line-height: 24px;

        font-weight: 700;
        color: var(--av-nav-primary);
    }

.a-table__head-desc,
    .a-table__head-mobile-desc {
        color: var(--av-nav-primary);
    }

.a-table__head-title,
    .a-table__head-mobile-title {
        font-size: 40px;
        line-height: 48px;
        font-weight: 300;
        letter-spacing: -0.8px;
    }

.a-table__head-label,
    .a-table__head-mobile-label {
        font-size: 14px;
        line-height: 24px;

        color: var(--av-fixed-success);
        margin-bottom: 4px;
    }

.a-table__head-product,
    .a-table__head-mobile-product {
        font-size: 24px;
        line-height: 24px;
        font-weight: bold;
    }

.a-table__row {
        display: flex;
    }

.a-table__row_vertical_middle .a-table__text {
                    display: flex;
                    align-items: center;
                    height: 100%;
                }

.a-table__row_vertical_bottom .a-table__text {
                    display: flex;
                    align-items: flex-end;
                    height: 100%;
                }

.a-table__row_with-indent {
            padding-top: 40px;
        }

@media (max-width: 767px) {
                    .a-table__row.a-table__row_no-scroll .a-table__cell {
                        flex-basis: auto;
                        flex-shrink: 1;
                        flex-grow: 1;
                    }

                        .a-table__row.a-table__row_no-scroll .a-table__cell:first-child {
                            flex-basis: 60%;
                        }
            }

@media (max-width: 767px) {

.a-table__row.a-table__row_multirow {
                flex-wrap: wrap
        }
                        .a-table__row.a-table__row_multirow .a-table__cell:first-child {
                            flex-basis: 100%;
                        }
            }

.a-table__body {
        display: block;
        overflow-x: scroll;
        margin: 0 -16px;
        padding: 0 calc(16px - 8px);
    }

.a-table__body::-webkit-scrollbar-thumb {
            background: rgb(193, 193, 193);
            border-radius: 3px;
        }

.a-table__body::-webkit-scrollbar {
            max-width: 6px;
            max-height: 6px;
        }

@media (min-width: 576px) {

.a-table__body {
            margin: 0 -32px;
            padding: 0 calc(32px - 8px)
    }
        }

@media (min-width: 1280px) {

.a-table__body {
            margin: 0 -64px;
            padding: 0 calc(64px - 8px)
    }
        }

@media (min-width: 1440px) {

.a-table__body {
            margin: 0 -8px;
            padding: 0
    }
        }

.a-table__cell {
        display: block;
        position: relative;
        padding-inline-start: 8px;
        padding-inline-end: 8px;
        border-bottom: 1px solid var(--av-brand-secondary-light);
        overflow: hidden;
    }

.a-table__cell_align_left {
                text-align: start !important;
            }

.a-table__cell_align_left:deep(.cta-description) {
                    justify-content: start;
                }

.a-table__cell_align_center {
                text-align: center !important;
            }

.a-table__cell_align_center .a-table__subtitle {
                    justify-content: center;
                }

.a-table__cell_align_right {
                text-align: end !important;
            }

.a-table__cell_align_right:deep(.cta-description) {
                    justify-content: end;
                }

.a-table__cell_without_border {
            border: none;
        }

.a-table__cell:deep(a:not(.a-button, .a-link)) {
            color: var(--av-brand-primary);
            text-decoration: none;
        }

.a-table__cell:deep(a:not(.a-button, .a-link)) del {
                color: var(--av-brand-primary);
            }

.a-table__cell:deep(a:not(.a-button, .a-link)):hover {
                color: var(--av-brand-secondary);
            }

.a-table__cell:deep(a:not(.a-button, .a-link)):focus {
                background: var(--av-brand-secondary-light);
                color: var(--av-brand-primary);
            }

.a-table__cell:deep(a:not(.a-button, .a-link)):active {
                background: none;
                color: var(--av-brand-primary);
            }

.a-table__subtitle {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 32px;
    }

.a-table__tooltip {
        position: relative;
        top: 3px;
        margin-inline-start: 8px;
    }

.a-table__glyph {
        display: block;
        margin-bottom: 8px;
        width: 24px;
        height: 24px;
    }

.a-table__category {
        font-size: 16px;
        line-height: 24px;

        vertical-align: middle;
        font-weight: 600;
        color: var(--av-nav-primary);
    }

.a-table__category:deep(.cta-description) {
            font-weight: 600;
        }

.a-table__description {
        margin-top: -8px;
        padding-inline-start: 48px;
        position: relative;
    }

.a-table__description-icon {
            top: 0;
            inset-inline-start: 0;
            width: 32px;
            height: 32px;
            position: absolute;
        }

.a-table__description-title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 700;

            margin-top: 8px;
            color: var(--av-nav-primary);
        }

.a-table__description-text {
            margin-top: 8px;
        }

.a-table__description-link {
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;

            margin-top: 8px;
        }

.a-table__description-link .a-link__glyph_position_right {
                    margin-inline-start: 8px;
                }

.a-table__description-note {
            font-size: 14px;
            line-height: 24px;

            margin-top: 8px;
            color: var(--av-fixed-light);
        }

.a-table__description-full {
        padding: 0 !important;
    }

.a-table__text {
        color: var(--av-fixed-secondary);
    }

.a-table__list {
        list-style-type: none;
    }

.a-table__list-item {
        margin-bottom: 16px;
    }

.a-table__list-item:last-child {
            margin-bottom: 8px;
        }

.a-table__check {
        min-width: 12px;
    }

.a-table__check-icon {
        position: absolute;
        top: 50%;
        inset-inline-start: 50%;
        transform: translate(-50%, -50%);
    }

.a-table__check-icon_true {
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: var(--av-fixed-success);
        }

.a-table__check-icon_false {
            width: 16px;
            height: 2px;
            background-color: var(--av-brand-secondary-light);
        }

.a-table__button-wrapper {
        padding: 16px 0;
    }

.a-table__button-wrapper:deep(.a-button) {
            width: 100%;
        }

.a-table .a-table-switcher {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

.a-table .a-table-switcher__input {
            position: absolute;
            visibility: hidden;
        }

.a-table .a-table-switcher__input:checked ~ .a-table-switcher__decor {
                background-color: var(--av-fixed-success);
            }

.a-table .a-table-switcher__input:checked ~ .a-table-switcher__decor::after {
                    transform: translate(17px, -50%);
                }

.a-table .a-table-switcher__text {
            font-size: 16px;
            line-height: 24px;

            display: inline-block;
            position: relative;
            margin-inline-end: 16px;
            color: var(--av-fixed-secondary);
        }

.a-table .a-table-switcher__decor {
            position: relative;
            flex: 0 0 37px;
            height: 22px;
            border-radius: 100px;
            background-color: var(--av-brand-light);
            transition: background-color 0.15s ease-out;
        }

.a-table .a-table-switcher__decor::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                inset-inline-start: 2px;
                width: 16px;
                border-radius: 100px;
                height: 16px;
                background-color: var(--av-inversed-primary);
                transition: transform 0.25s ease-out;
            }

.a-table i,
    .a-table em {
        font-style: italic;
    }

.a-table b {
        font-weight: bold;
    }

.a-table strong {
        font-weight: 600;
    }

.a-table:deep(.cta) {
        font: var(--font-default);
    }

.a-table:deep(.cta-description) {
        margin: 0;
    }

.a-table .a-table__subtitle:deep(.cta) {
            font-size: 18px;
            line-height: 32px;
        }

.a-table__lead {
        font-size: 16px;
        line-height: 24px;
        margin-top: 48px;
        margin-bottom: 40px;
        color: #384A63;
    }

@media (min-width: 768px) {

.a-table__lead {
            margin-bottom: 56px
    }
        }

@media (min-width: 1024px) {

.a-table__lead {
            max-width: 632px
    }
        }

@media (min-width: 1280px) {

.a-table__lead {
            max-width: 760px
    }
        }

@media (min-width: 1440px) {

.a-table__lead {
            max-width: 870px
    }
        }

.a-table_type-1 .a-table__head-cell,
        .a-table_type-1 .a-table__cell {
            border-bottom: 1px solid rgba(56, 74, 99, .2);
        }

.a-table_type-1 .a-table__head-cell:first-child, .a-table_type-1 .a-table__cell:first-child {
                box-shadow: 3px 0 2px 0 rgba(230, 231, 233, .5);
            }

@media (min-width: 768px) {

.a-table_type-1 .a-table__head-cell:first-child, .a-table_type-1 .a-table__cell:first-child {
                    box-shadow: none
            }
                }

.a-table_type-1 .a-table__head-cell:not(:first-of-type), .a-table_type-1 .a-table__cell:not(:first-of-type) {
                padding: 24px 12px;
            }

.a-table_type-1 .a-table__head-text {
            font-size: 18px;
            line-height: 24px;
            font-weight: 600;
        }

.a-table_type-1 .a-table__text {
            color: #384A63
        }

.a-table_lte-3-cols .a-table__head-cell:first-child,
        .a-table_lte-3-cols .a-table__cell:first-child {
            flex-grow: 1;
        }

.a-table_type-1 .syncscroll {
    overflow-x: unset;
}
