<template>
    <component
        :is="to ? 'dynamic-link' : 'div'"
        class="a-value-card"
        :to="to ? to : null"
        :class="{ 'a-value-card_linked': to }"
    >
        <div v-if="image" :class="className">
            <a-picture class="a-value-card__img" :link="image" :alt="imageAlt" />
        </div>
        <div class="a-value-card__content">
            <div class="a-value-card__header">
                <component :is="titleTagName" class="a-value-card__title">
                    {{ title }}
                </component>
                <a-icon
                    v-if="icon"
                    class="a-value-card__icon"
                    :number="icon"
                    :alt="iconAlt || title"
                />
            </div>
            <div class="a-value-card__text" :class="{'has-button': ctaButtons}">
                <a-dangerous-html :content="$slots.default[0] && $slots.default[0].text" />
            </div>

            <div v-if="ctaButtons" class="cta-buttons">
                <a-button
                    v-for="(button, index) in ctaButtons"
                    :key="index"
                    v-bind="button"
                />
            </div>
        </div>
    </component>
</template>

<script>
import AButton from '@core/components/button/button.vue';
import ADangerousHtml from '@core/components/dangerous-html/dangerous-html.vue';
import DynamicLink from '@core/components/dynamic-link/dynamic-link.vue';
import AIcon from '@core/components/icon/icon.vue';
import APicture from '@core/components/picture/picture.vue';

export default {
    name: 'AValueCard',

    components: {
        AButton,
        ADangerousHtml,
        DynamicLink,
        AIcon,
        APicture,
    },

    props: {
        to: {
            type: String,
            default: undefined,
        },

        /**
         * Image URL
         */
        image: {
            type: String,
            default: undefined,
        },

        imageAlt: {
            type: String,
            default: undefined,
        },

        title: {
            type: String,
            required: true,
        },

        titleTag: {
            type: String,
            default: 'div',
        },

        icon: {
            type: String,
            default: '',
        },

        iconAlt: {
            type: String,
            default: '',
        },
        ctaButtons: {
            type: Array,
            default: null,
        },
        hasCardsBackground: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        titleTagName() {
            return this.titleTag;
        },
        className() {
            return [
                'a-value-card__img-container',
                { 'a-value-card-has-background': this.hasCardsBackground },
            ];
        },
    },
};
</script>

<style lang="postcss" scoped>
.a-value-card {
    border: var(--av-border);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(56, 74, 99, 0.1);
    overflow: hidden;
    background-color: var(--av-inversed-primary);
    height: 100%;
    width: 100%;

    &_linked {
        display: block;
        text-decoration: none;

        .a-value-card {
            &__title {
                color: var(--av-brand-secondary);
                transition: color 0.15s ease-out;
            }

            &__text {
                color: var(--av-fixed-secondary);
            }
        }

        &:hover {
            box-shadow: none;

            .a-value-card {
                &__title {
                    color: var(--av-nav-secondary);
                    transition: color 0.15s ease-out;
                }
            }
        }

        a {
            display: none;
        }
    }

    &__text {
        &.has-button {
            margin-bottom: 24px;
        }
    }

    .cta-buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        margin-top: auto;
    }

    &__img-container {
        height: 246px;
    }

    &__img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            position: relative;
            overflow: hidden;
        }

        &:deep(.a-picture__img) {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                position: absolute;
                top: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                max-width: none;
                height: auto;
            }
        }
    }

    &__content {
        padding: 16px 24px 32px;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__header {
        margin-bottom: 16px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__title {
        @mixin title-accent;

        color: var(--av-nav-primary);
    }

    &__icon {
        margin-inline-start: 24px;
        width: 64px;
        height: 64px;
    }
}

.a-value-card-has-background {
    padding-top: 32px;
    padding-inline-start: 32px;
    background-color: var(--av-solid-brand-secondary-light);
}
</style>
