<template>
    <div class="video-card-extended">
        <a-media type="video" v-bind="$props" />
        <div v-if="hasTextContent" class="video-card-extended__text-content">
            <a-link
                v-if="title"
                class="video-card-extended__title"
                :to="youtubeUrl"
                :text="title"
            />
            <div v-if="desc" class="video-card-extended__desc">
                {{ desc }}
            </div>
            <div v-if="viewsText" class="video-card-extended__views">
                {{ viewsText }}
            </div>
        </div>
    </div>
</template>

<script>
import ALink from '@core/components/link/link.vue';
import AMedia from '@core/components/media/media.vue';
import { SIZES } from '@core/components/video-card/constants.js';

export default {
    name: 'VideoCardExtended',

    components: {
        ALink,
        AMedia,
    },

    props: {
        title: {
            type: String,
            default: '',
        },

        desc: {
            type: String,
            default: '',
        },

        viewsText: {
            type: String,
            default: '',
        },

        /**
         * Youtube id
         */
        ytId: {
            type: String,
            required: true,
        },

        ytParams: {
            type: Object,
            default: () => ({ autoplay: 1 }),
        },

        /**
         * Text
         */
        text: {
            type: String,
            default: undefined,
        },

        /**
         * Video time
         */
        time: {
            type: String,
            default: undefined,
        },

        /**
         * Image URL
         */
        image: {
            type: String,
            required: true,
        },

        /**
         * Image alt
         */
        imageAlt: {
            type: String,
            default: undefined,
        },

        /**
         * Size
         */
        size: {
            type: String,
            default: undefined,
            validator: (value) => value === undefined || SIZES.includes(value),
        },
    },

    computed: {
        hasTextContent() {
            return this.title || this.desc || this.viewsText;
        },

        youtubeUrl() {
            return `https://www.youtube.com/watch?v=${this.ytId}`;
        },
    },
};
</script>

<style lang="postcss" scoped>
.video-card-extended {
    max-width: 100%;

    @media (--viewport-mobile-wide) {
        max-width: 100%;
    }

    &__text-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        padding: 16px;
    }

    &__title {
        &:deep(.a-link__content) {
            @mixin lead-accent;
        }
    }

    &__desc {
        @mixin paragraph;
        color: var(--av-fixed-secondary);
    }

    &__views {
        @mixin paragraph;
        color: var(--av-fixed-lighter);
    }
}
</style>
