/* Table component cell colspan
base - is width of the current cell,
standard - is width of the standard cell of current layout */

/* TODO: Replace with global class with base horizontal padding */

/* TODO: Replace with global class with base vertical padding */

.a-modal {
    display: none;
    position: fixed;
    z-index: 2147483650;
}

/* this must be on top of geoIP popup which has z-index: 2147483647 because cookie popup has z-index: 2147483645 */

.a-modal.is-modal-open {
        display: block;
    }

.a-modal .wrapper {
        position: fixed;
        top: 0;
        inset-inline-start: 0;
        inset-inline-end: 0;
        bottom: 0;
        min-width: 100vw;
        min-height: 100vh;
        overflow: auto;
        -webkit-user-select: none;
           -moz-user-select: none;
                user-select: none;
        background: var(--av-fixed-secondary);
        padding: 48px 16px;
    }

@media (min-width: 576px) {

.a-modal .wrapper {
            padding: 48px 32px
    }
        }

@media (min-width: 1024px) {

.a-modal .wrapper {
            padding: 48px
    }
        }

.a-modal .wrapper .gallery {
            display: none;
        }

@media (min-width: 768px) {

.a-modal .wrapper .gallery {
                display: block
        }
            }

.a-modal .overlay {
        position:absolute;
        top: 0;
        inset-inline-start: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        cursor: default;
    }

.a-modal .content {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

@media (min-width: 1024px) {

.a-modal .content {
            width: calc(((100% - (16px * 11)) / 12) * 10 + (16px * (10 - 1)));
            margin: 0 auto
    }
        }

.a-modal .content :deep(> video, > picture) {
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

.a-modal .content .inner {
            position: relative;
            max-width: 100%;
            max-height: 100%;
        }

.a-modal .content .inner > picture {
                height: 100%;
            }

.a-modal .content .inner > video {
                max-height: 100%;
                max-width: 100%;
            }

.a-modal .close, .a-modal .close-mobile {
        border: 1px solid var(--av-inversed-primary);
        border-radius: 50%;
        padding: 8px;
        transition: all 0.3s;
    }

.a-modal .close:hover, .a-modal .close-mobile:hover {
            background: var(--av-fixed-secondary);
        }

.a-modal .close :deep(.a-glyph), .a-modal .close-mobile :deep(.a-glyph) {
            margin: 0;
            fill: var(--av-inversed-primary);
        }

.a-modal .close-mobile {
        display: flex;
        position: absolute;
        top: 8px;
        inset-inline-end: 8px;
    }

@media (min-width: 1024px) {

.a-modal .close-mobile {
            display: none
    }
        }

.a-modal .close {
        display: none;
        position: absolute;
        top: 0;
        inset-inline-end: -48px;
    }

@media (min-width: 1024px) {

.a-modal .close {
            display: flex
    }
        }

.a-modal.type_modal .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

.a-modal.type_modal .content {
            margin: auto 0;
            height: auto;
        }

.a-modal.type_modal .inner {
            width: 100%;
        }

.a-modal.type_playlist :deep(.a-youtube--modal), .a-modal.type_youtube :deep(.a-youtube--modal) {
            aspect-ratio: 16 / 9;
            width: 100%;
            height: auto;
            max-height: 100%;
            max-width: 100%;
            top: 50%;
            inset-inline-start: 50%;
            transform: translate(-50%, -50%);
        }

@media (min-width: 1024px) {

.a-modal.type_playlist :deep(.a-youtube--modal), .a-modal.type_youtube :deep(.a-youtube--modal) {
                height: 100%;
                width: auto
        }
            }

.a-modal.type_playlist .inner, .a-modal.type_youtube .inner {
            width: 100%;
            height: 100%;
        }

@media (min-width: 768px) {

.a-modal.type_playlist .content :deep(.a-youtube--modal) {
                    height: 100%
            }
                }

.a-modal.type_playlist .content {
            height: calc(100% - 230px);
}

@media (min-width: 576px) {

.a-modal.type_playlist .content {
                height: calc(100% - 216px)
        }
            }

@media (min-width: 768px) {

.a-modal.type_playlist .content {
                height: calc(100% - 168px)
        }
            }

@media (min-width: 1024px) {

.a-modal.type_playlist .content {
                height: calc(100% - 144px)
        }
            }

@media (min-width: 1280px) {

.a-modal.type_playlist .content {
                height: calc(100% - 120px)
        }
            }

.a-modal.type_playlist .playlist {
            width: 100%;
            margin-top: 32px;
        }

@media (min-width: 576px) {

.a-modal.type_playlist .playlist {
                margin-top: 40px
        }
            }

@media (min-width: 1024px) {

.a-modal.type_playlist .playlist {
                width: calc(((100% - (16px * 11)) / 12) * 10 + (16px * (10 - 1)));
                margin: 40px auto 0
        }
            }

.a-modal.type_screenshot .content, .a-modal.type_gallery .content {
            width: calc(((100% - (16px * 11)) / 12) * 12 + (16px * (12 - 1)));
        }

.a-modal.type_screenshot .content .loader, .a-modal.type_gallery .content .loader {
                position: absolute !important;
                z-index: 0;
                pointer-events: none;
            }

.a-modal.type_screenshot .content .loader :deep(.el-text), .a-modal.type_gallery .content .loader :deep(.el-text) {
                    display: block;
                    padding-top:16px;
                    width: 250px;
                    color: var(--av-inversed-primary);
                    opacity: 0.7;
                }

.a-modal.type_screenshot :deep(.a-picture), .a-modal.type_gallery :deep(.a-picture) {
            box-shadow: var(--av-shadow-modal);
        }

.a-modal.type_screenshot .wrapper, .a-modal.type_gallery .wrapper {
            padding: 16px;
        }

@media (min-width: 576px) {

.a-modal.type_screenshot .wrapper, .a-modal.type_gallery .wrapper {
                padding: 16px
        }
            }

@media (min-width: 768px) {

.a-modal.type_screenshot .wrapper, .a-modal.type_gallery .wrapper {
                padding: 48px 32px
        }
            }

@media (min-width: 1280px) {

.a-modal.type_screenshot .wrapper, .a-modal.type_gallery .wrapper {
                padding: 48px
        }
            }

.a-modal.type_screenshot .close-mobile, .a-modal.type_gallery .close-mobile {
            background: var(--av-fixed-secondary);
        }

@media (min-width: 768px) {

.a-modal.type_screenshot .close-mobile, .a-modal.type_gallery .close-mobile {
                background: transparent
        }
                .a-modal.type_screenshot .close-mobile:hover, .a-modal.type_gallery .close-mobile:hover {
                    background: var(--av-fixed-secondary);
                }
            }

@media (min-width: 1024px) {

.a-modal.type_screenshot .close-mobile, .a-modal.type_gallery .close-mobile {
                display: flex
        }
            }

@media (min-width: 1280px) {

.a-modal.type_screenshot .close-mobile, .a-modal.type_gallery .close-mobile {
                top: 16px;
                inset-inline-end: 16px
        }
            }

.a-modal.type_screenshot .close, .a-modal.type_gallery .close {
            display: none;
        }

@media (min-width: 768px) {

.a-modal.type_gallery .wrapper {
                padding-bottom: 0
        }
            }

.a-modal.type_gallery .wrapper .close-mobile {
                display: none;
            }

@media (min-width: 768px) {

.a-modal.type_gallery .wrapper .close-mobile {
                    display: flex
            }
                }

.a-modal.type_gallery .wrapper.is-visible .close-mobile {
                    display: flex;
                }

.a-modal.type_gallery .wrapper.is-visible .gallery {
                    display: block;
                }

@media (min-width: 768px) {

.a-modal.type_gallery .content {
                height: calc(100% - 144px)
        }
            }

[dir='rtl'] .a-modal.type_playlist :deep(.a-youtube--modal), [dir='rtl'] .a-modal.type_youtube :deep(.a-youtube--modal) {
                inset-inline-start: unset;
                inset-inline-end: 50%;
            }
