<template>
    <div ref="pagination" class="pagination" :class="cssClasses">
        <div v-if="isMounted" class="pagination-wrapper">
            <div
                class="arrow page-button"
                :class="{ 'arrow--disabled': leftArrowDisabled }"
                @click="goPrevPage"
            >
                <a-glyph name="arrow-left" />
            </div>

            <template v-if="!isMobileBrowser && totalPages > maxVisiblePages && firstVisiblePage > 1">
                <a
                    :href="getPageURL(1)"
                    target="_self"
                    class="page-button"
                    @click.stop.prevent="selectPage(1)"
                >
                    1
                </a>

                <template v-if="firstVisiblePage > 2 && lowerRange.length > 0">
                    <el-select
                        v-if="lowerRange.length > 1"
                        ref="prevSelect"
                        v-model="selectedPage"
                        name="page-lower-range"
                        class="page-button"
                        popper-class="pagination-dropdown"
                        :popper-max-width="popperSize.w"
                        :popper-max-height="popperSize.h"
                        placeholder="..."
                        custom-hint=""
                    >
                        <el-option
                            v-for="pageNum in lowerRange"
                            :key="pageNum"
                            class="page-button range-option"
                            :value="null"
                            force-visible
                            @click.prevent="selectPage(pageNum)"
                        >
                            <a :href="getPageURL(pageNum)" @click.prevent="selectPage(pageNum)">
                                {{ pageNum }}
                            </a>
                        </el-option>
                    </el-select>
                    <a
                        v-else
                        :href="getPageURL(lowerRange[0])"
                        target="_self"
                        class="page-button"
                        @click.stop.prevent="selectPage(lowerRange[0])"
                    >
                        {{ lowerRange[0] }}
                    </a>
                </template>
            </template>

            <a
                v-for="pageNum in visiblePages"
                :key="pageNum"
                :href="getPageURL(pageNum)"
                target="_self"
                class="page-button"
                :class="{ 'page-button--current': pageNum === activePage }"
                @click.stop.prevent="selectPage(pageNum)"
            >
                {{ pageNum }}
            </a>

            <template v-if="!isMobileBrowser && currentPage !== totalPages && lastVisiblePage !== totalPages">
                <template v-if="lastVisiblePage !== totalPages - 1 && upperRange.length > 0">
                    <el-select
                        v-if="upperRange.length > 1"
                        ref="nextSelect"
                        v-model="selectedPage"
                        name="page-upper-range"
                        class="page-button"
                        popper-class="pagination-dropdown"
                        :popper-max-width="popperSize.w"
                        :popper-max-height="popperSize.h"
                        placeholder="..."
                    >
                        <el-option
                            v-for="pageNum in upperRange"
                            :key="pageNum"
                            class="page-button range-option"
                            :value="null"
                            force-visible
                            @click.prevent="selectPage(pageNum)"
                        >
                            <a :href="getPageURL(pageNum)" @click.prevent="selectPage(pageNum)">
                                {{ pageNum }}
                            </a>
                        </el-option>
                    </el-select>
                    <a
                        v-else
                        :href="getPageURL(upperRange[0])"
                        target="_self"
                        class="page-button"
                        @click.stop.prevent="selectPage(upperRange[0])"
                    >
                        {{ upperRange[0] }}
                    </a>
                </template>

                <a
                    :href="getPageURL(totalPages)"
                    target="_self"
                    class="page-button"
                    @click.stop.prevent="selectPage(totalPages)"
                >
                    {{ totalPages }}
                </a>
            </template>

            <div
                class="arrow arrow--right page-button"
                :class="{ 'arrow--disabled': rightArrowDisabled }"
                @click="goNextPage"
            >
                <a-glyph name="arrow-left" />
            </div>
        </div>
        <div v-else class="ssr-pagination">
            <a
                v-for="pageNum in totalPages"
                :key="pageNum"
                :href="getPageURL(pageNum)"
                target="_self"
            >
                {{ pageNum }}
            </a>
        </div>
    </div>
</template>

<script lang="ts">
import AGlyph from '@core/components/glyph/glyph.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import { LOCALE_DEFAULT } from '@model/const/locales';
import commonUtils from '@utils/common';
import { PaginationTheme } from './constants.ts';

const MAX_VISIBLE_PAGES = 5;
const PAGES_OFFSET = 2;

export default {
    name: 'APagination',

    components: {
        AGlyph,
        ElSelect: () => import('@uikit/ui-kit/packages/select/index.js'),
        ElOption: () => import('@uikit/ui-kit/packages/option/index.js'),
    },

    mixins: [breakpoint],

    props: {
        theme: {
            type: String,
            validator: (value) => !value || PaginationTheme[value],
            default: PaginationTheme.Light,
        },
        totalPages: {
            type: Number,
            required: true,
        },
        currentPage: {
            type: Number,
            required: true,
            validator: (value) => value > 0,
        },
        urlTemplate: {
            type: String,
            required: true,
        },
        scrollTo: {
            type: Object,
            default: null,
        },
        clickHandler: {
            type: Function,
            default: null,
        },
        pagesToShow: {
            type: Number,
            default: MAX_VISIBLE_PAGES,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data: () => ({
        isMounted: false,
        isLocked: false,
        activePage: 0,
        selectedPage: '',
    }),

    computed: {
        isMobileBrowser() {
            if (typeof window === 'undefined') return !this.isDesktop;
            const hasTouchSupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
            if (!this.isDesktop) return true;
            return hasTouchSupport;
        },

        needFix() {
            return this.totalPages < this.lastVisiblePage;
        },

        locale() {
            return this.$route?.params?.locale || LOCALE_DEFAULT;
        },

        popperSize() {
            return { w: '66px', h: '256px' };
        },

        cssClasses() {
            return {
                [`pagination--${this.theme.toLowerCase()}`]: true,
                'pagination--disabled': this.disabled,
            };
        },

        leftArrowDisabled() {
            return this.activePage === 1;
        },

        rightArrowDisabled() {
            return this.activePage === this.totalPages;
        },

        maxVisiblePages() {
            return Math.min(this.totalPages, this.pagesToShow);
        },

        pageOffset() {
            return Math.min(PAGES_OFFSET, Math.floor(this.pagesToShow / 2));
        },

        firstVisiblePage() {
            if (this.totalPages <= this.maxVisiblePages) return 1;

            // hacks for edge cases
            if (!this.isMobileBrowser) {
                if (this.activePage >= this.totalPages - this.pageOffset + 2) {
                    return Math.max(this.activePage - this.pageOffset - 4, 1);
                }

                if (this.activePage >= this.totalPages - this.pageOffset + 1) {
                    return Math.max(this.activePage - this.pageOffset - 3, 1);
                }

                if (this.activePage >= this.totalPages - this.pageOffset) {
                    return Math.max(this.activePage - this.pageOffset - 2, 1);
                }

                if (this.activePage >= this.totalPages - this.pageOffset - 1) {
                    return Math.max(this.activePage - this.pageOffset - 1, 1);
                }
            }

            if (this.activePage === this.totalPages) {
                return Math.max(this.activePage - this.maxVisiblePages + 1, 1);
            }

            return Math.max(this.activePage - this.pageOffset, 1);
        },

        lastVisiblePage() {
            if (this.activePage < this.maxVisiblePages) {
                if (this.isMobileBrowser) {
                    return Math.min(this.firstVisiblePage + this.pagesToShow - 1, this.totalPages);
                }
                const pagesToShow = this.pagesToShow > this.totalPages - 1 ? this.totalPages - 1 : this.pagesToShow;
                return Math.min(this.activePage + this.pageOffset + (pagesToShow - this.activePage), this.totalPages);
            }

            if (this.activePage <= this.maxVisiblePages / 2 + 1) {
                return this.maxVisiblePages;
            }

            return Math.min(this.activePage + this.pageOffset, this.totalPages);
        },

        visiblePages() {
            let lastVisiblePage;

            if (this.needFix) {
                lastVisiblePage = Math.min(this.lastVisiblePage, this.maxVisiblePages);
            } else {
                lastVisiblePage = this.lastVisiblePage;
            }

            const diff = lastVisiblePage - this.firstVisiblePage;

            const rangeMoreThanAvailable =
                lastVisiblePage > this.maxVisiblePages &&
                diff < (this.maxVisiblePages - 1) &&
                (this.lastVisiblePage - this.maxVisiblePages + 1) > 0;

            // fixing range with last visible page
            if (rangeMoreThanAvailable) {
                return commonUtils.range(lastVisiblePage - this.maxVisiblePages + 1, lastVisiblePage);
            }

            if (this.needFix && this.lastVisiblePage !== lastVisiblePage) {
                return commonUtils.range(this.firstVisiblePage, lastVisiblePage - 1);
            }
            return commonUtils.range(this.firstVisiblePage, lastVisiblePage);
        },

        lowerRange() {
            const res = commonUtils.range(2, this.firstVisiblePage - 1);
            return res.filter((pageNum) => !this.visiblePages.includes(pageNum));
        },

        upperRange() {
            const res = commonUtils.range(this.lastVisiblePage + 1, this.totalPages - 1);
            return res.filter((pageNum) => !this.visiblePages.includes(pageNum));
        },
    },

    watch: {
        selectedPage() {
            this.selectedPage = '';
            if (this.$refs?.prevSelect) this.$refs.prevSelect.$el?.querySelector('.el-input')?.classList.remove('is-focus');
            if (this.$refs?.nextSelect) this.$refs.nextSelect.$el?.querySelector('.el-input')?.classList.remove('is-focus');
        },

        currentPage(value) {
            this.activePage = value;
        },
    },

    created() {
        this.activePage = this.currentPage;
    },

    mounted() {
        this.isMounted = true;
    },

    methods: {
        getPageURL(pageNum: number) {
            const url = this.urlTemplate.replace('$PAGE_NUMBER$', pageNum);
            return url.replace('$LOCALE$', this.locale);
        },

        async resetSelects() {
            if (!this.$refs) return;

            await this.$nextTick();

            if (this.$refs.prevSelect) {
                this.$refs.prevSelect.updateValue('');
                this.$refs.prevSelect.handleClose?.();
            }

            if (this.$refs.nextSelect) {
                this.$refs.nextSelect.updateValue('');
                this.$refs.nextSelect.handleClose?.();
            }
        },

        async selectPage(pageNum: number) {
            if (this.isLocked) return;
            this.isLocked = true;
            this.pushGaClickEvent(pageNum);

            // Handle page change with optional clickHandler or default routing
            if (typeof this.clickHandler === 'function') {
                await this.clickHandler(pageNum, async () => {
                    if (this.$router) await this.$router.push(this.getPageURL(pageNum));
                });
            } else if (this.$router) {
                await this.$router.push(this.getPageURL(pageNum));
            }

            this.activePage = pageNum;
            await this.resetSelects();
            this.isLocked = false;

            if (this.scrollTo instanceof HTMLElement) {
                this.scrollTo.scrollIntoView({ behavior: 'smooth' });
            } else if (this.scrollTo?.$el instanceof HTMLElement) {
                this.scrollTo.$el.scrollIntoView({ behavior: 'smooth' });
            }
        },

        lock() {
            this.isLocked = true;
        },

        unlock() {
            this.isLocked = false;
        },

        goPrevPage() {
            this.selectPage(this.activePage - 1);
        },

        goNextPage() {
            this.selectPage(this.activePage + 1);
        },

        pushGaClickEvent(pageNum: number) {
            const event = {
                eventCategory: 'Interactions',
                eventAction: 'link',
                eventLabel: this.getPageURL(pageNum),
                eventContext: 'click',
                eventContent: `${pageNum}`,
            };
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(event);
        },
    },
};
</script>

<style lang="postcss" scoped>
.pagination-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ssr-pagination {
    display: none;
}

.page-button {
    @mixin paragraph-accent;
    width: 44px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-variant-numeric: tabular-nums;
    user-select: none;
    cursor: pointer;
    border-radius: 4px;
    color: var(--av-brand-primary);

    svg {
        fill: var(--av-brand-primary);
    }

    &:hover {
        background: var(--av-brand-accent);
    }

    &--current,
    &:active {
        color: var(--av-nav-primary);
        background: var(--el-secondary-active);

        svg {
            fill: var(--av-nav-primary);
        }
    }

    &--current {
        pointer-events: none;
    }
}

.page-button.el-select {
    &:hover {
        &:deep(.el-input__container) {
            background: none;
        }
    }

    &:active {
        &:deep(.el-input__container) {
            background: none;

            .el-input__editor::placeholder {
                color: var(--av-nav-primary);
            }
        }
    }

    &:deep(.el-input) {
        .el-input__wrapper {
            padding: 0;
            text-align: center;
        }

        .el-input__suffix {
            display: none;
        }

        .el-input__container {
            width: 44px;
            height: 32px;
            border: 0;
        }

        .el-select-input {
            width: 44px;
        }

        .el-input__editor::placeholder {
            @mixin paragraph-accent;
            color: var(--av-nav-primary);
            transform: translateY(4px);
        }
    }
}

.arrow {
    width: 32px;

    &--right {
        transform: scaleX(-1);
    }

    &--disabled {
        pointer-events: none;
        opacity: 0.3;
    }
}

.pagination {
    padding: 16px 24px;

    &--navy {
        background: var(--av-nav-primary);

        .page-button {
            color: rgba(166, 203, 255, 1);

            svg {
                fill: rgba(166, 203, 255, 1);
            }

            &:hover {
                color: var(--av-inversed-primary);
                background: rgba(38, 104, 197, 0.3);

                svg {
                    fill: var(--av-inversed-primary);
                }
            }

            &--current,
            &:active {
                color: var(--av-inversed-primary);
                background: rgba(38, 104, 197, 0.6);

                svg {
                    fill: var(--av-inversed-primary);
                }
            }
        }

        .page-button.el-select {
            &:deep(.is-focus) {
                border-radius: 4px;
                color: var(--av-inversed-primary);
                background: rgba(38, 104, 197, 0.6);
            }

            &:deep(.el-input__container) {
                background: transparent;
            }

            &:deep(.el-input__editor) {
                &::placeholder {
                    color: #000;
                }
            }

            &:hover {
                &:deep(.el-input__container) {
                    .el-input__editor::placeholder {
                        color: var(--av-inversed-primary);
                    }
                }
            }

            &:active {
                &:deep(.el-input__container) {
                    .el-input__editor::placeholder {
                        color: var(--av-inversed-primary);
                    }
                }
            }
        }
    }

    &--disabled {
        pointer-events: none;
    }
}

.pagination--light .page-button.el-select {
    &:deep(.is-focus .el-input__container) {
        border-radius: 4px;
        color: #00204D;
        background: rgba(38, 104, 197, 0.3);
    }
}
</style>

<style lang="postcss">
.pagination-dropdown .el-scrollbar__bar {
    display: none;
}

.pagination-dropdown .el-select-dropdown__list {
    overflow-x: hidden;
}

.el-select-dropdown__item.range-option {
    @mixin paragraph;
    width: 44px;
    height: 40px;
    padding: 0;
    justify-content: center;
    overflow-x: hidden;
    border-radius: 0;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        color: var(--av-brand-primary);
    }
}
</style>
